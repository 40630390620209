@import-normalize; 

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  /* font-weight: 200; */
}
@font-face {
  font-family: Montserrat-Regular;
  src: url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  /* font-weight: 200; */
}
@font-face {
  font-family: Montserrat-Medium;
  src: url('../../assets/fonts/Montserrat-Medium.ttf') format('truetype');
  /* font-weight: 200; */
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  /* font-weight: 200; */
}

code {
  font-family: Montserrat-Regular, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
